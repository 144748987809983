import { useRequireAuth } from "lib/use-auth";
import { useRouter } from "next/router";
import React, { createContext, useContext } from "react";
import { KeyedMutator } from "swr";
import { useActiveOrganization } from "./ActiveOrganizationProvider";
import { useFile, useVersions } from "@/services/files.service";
import { File } from "@/types/app/file";

// Context provider that primarily provides the file object for the current file being viewed.
// The name is archaic from when there was a separate 'monitoring' app within humanloop.
// It should be renamed to `useFileContext` or similar.
interface MonitoringFileContextInterface {
  /** Project is undefined only if its not yet loaded from API*/
  file: File | undefined;
  queryFileId: string | undefined;
  mutate: KeyedMutator<File>;
  error: any;
  loading: boolean;
}

export const MonitoringFileContext = createContext<MonitoringFileContextInterface | undefined>(undefined);

// Overloaded function signatures so we guarantee the type of the return value.
export function useMonitoringFile(): MonitoringFileContextInterface;
export function useMonitoringFile(options: { allowUndefined: boolean }): MonitoringFileContextInterface | null;

export function useMonitoringFile(options?: { allowUndefined: boolean }) {
  const context = useContext(MonitoringFileContext);

  if (context === undefined) {
    if (options?.allowUndefined) {
      return null;
    }
    throw new Error("useMonitoringFile must be used within a MonitoringFileContextProvider");
  }

  return context;
}
export const MonitoringFileContextProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const _ = useRequireAuth();
  const router = useRouter();
  // TODO - The query param will still be projectId, will migrate with the url updates
  const fileId = router.query.projectId as string;

  const { organization, slug } = useActiveOrganization();

  // Check if the user is in the library or the project view
  // We don't want to fetch the file if we are in the library view
  const isLibrary = slug && router.asPath.includes(`${slug}/library`);

  const { file, mutate, error, loading } = useFile(!isLibrary && organization && fileId ? { fileId } : undefined);
  // With V5 API there should always be a File object. However, as protection if for some reason,
  // there is nothing deployed, then just show the first version as the File.
  // Remove this if we genuinely can trust that there will always be a file object.
  // Only fetch versions if we error on the file fetch to reduce API calls.
  const { versions } = useVersions(error ? fileId : null);

  return (
    <MonitoringFileContext.Provider
      value={{
        // Fallback to the latest version.
        file: file ? file : versions ? versions[0] : undefined,
        // Provided as it's more immediately available than `project.id`. `project.id`
        // goes from 'pr_1' to undefined to 'pr_2` when switching projects, while
        // this should go from 'pr_1' to 'pr_2' immediately.
        queryFileId: fileId,
        mutate,
        error,
        loading,
      }}
    >
      {children}
    </MonitoringFileContext.Provider>
  );
};
