import React from "react";
import DOMPurify from "isomorphic-dompurify";
import { marked } from "marked";

/**
 * A React component that safely renders markdown content by sanitizing the HTML
 * using DOMPurify before rendering.
 *
 * We have moved away from markdown-to-jsx since it would crash the
 * browser tab on examples where the content was malformed.
 *
 * We infer the 'markdown' type non-deterministically when rendering chat messages,
 * so false positives are possible.
 *
 * @param children - The markdown content to be rendered
 * @param props - Additional props to be passed to the container in which the markdown is rendered
 * @returns A div element with sanitized HTML content
 */
export const SafeMarkdown = ({ children, ...props }: React.ComponentProps<"div"> & { children: string }) => {
  const parsedHtml = marked.parse(children, {
    async: false,
  });
  const cleanHTML = DOMPurify.sanitize(parsedHtml);
  return <div {...props} dangerouslySetInnerHTML={{ __html: cleanHTML }} />;
};

/**
 * Render code snippets passed in markdown with dedicated classNames
 *
 * @param children - The markdown string to be rendered
 * @param props - Additional props forwarded to the SafeMarkdown component
 * @param props.className - Additional styles to be applied to the SafeMarkdown component
 *
 * NOTE: The component intended to wrap the SafeMarkdown in a <CodePreview> component.
 * This was commented out with the following reasoning:
 *
 * TODO fix syntax highlighting/background of pre here
 * pre: { props: { className: "bg-transparent p-0" } },
 * code: { component: CodePreview, props: codePreviewProps },
 *
 */
export const SafeMarkdownStyledWithCode = ({
  children,
  ...props
}: React.ComponentProps<"div"> & { children: string }) => (
  <SafeMarkdown {...props} className={`prose prose-xs ${props.className}`}>
    {children}
  </SafeMarkdown>
);
