import TruncateMarkup from "react-truncate-markup";
import { classNames, cn } from "lib/utils";
import React, { useState } from "react";
import { SafeMarkdown } from "./SafeMarkdown";
import { useTimeout } from "usehooks-ts";
import { PRIVACY_CLASSNAMES } from "@/lib/analytics/shared";
import Button from "@components/library/Button";
import { None } from "./Tables/Logs/LogCells";

export const DescriptionList = ({
  className,
  style,
  ...props
}: React.PropsWithChildren<React.HTMLAttributes<HTMLElement>>) => (
  <dl
    className={classNames("grid grid-cols-[120px_auto] items-start gap-x-12 gap-y-16", className)}
    style={style}
    {...props}
  />
);

export const DescriptionTerm = ({
  children,
  className,
  ...props
}: React.PropsWithChildren<React.HTMLAttributes<HTMLElement>>) => {
  return (
    // py-2 seems unnecessary on this and DescriptionDetails (why not just increase the gap?) but its in the designs and has been here for a long time.
    <dt {...props} className={classNames("flex items-center py-2 text-13-20 text-oldgray-800", className)}>
      {children}
    </dt>
  );
};

export const DescriptionDetails = ({
  children,
  className,
  ...props
}: React.PropsWithChildren<React.HTMLAttributes<HTMLElement>>) => {
  return (
    <dd
      {...props}
      className={classNames(
        "min-w-0 max-w-prose py-2 text-13-20 text-text-base-1 last:mb-0",
        className,
        PRIVACY_CLASSNAMES["high"],
      )}
    >
      {children ? children : <None />}
    </dd>
  );
};

export const TruncatedExpandable = ({
  children,
  lines = 4,
  className,
  ellipsis,
}: React.PropsWithChildren<{ className?: string; lines?: number; ellipsis?: React.ReactNode }>) => {
  const [expanded, setExpanded] = useState(false);
  const [, setCount] = useState(0);

  // This is just a dumb hack to force a re-render after 100ms. There is a hard to fix bug where the truncations
  // were wrong on first render (like it would get the first character and then just show the ellipsis). It calculates
  // it correctly after a re-render so we just do that.
  useTimeout(() => {
    setCount((prevCount) => prevCount + 1);
  }, 100);

  return (
    <div className={classNames(className)}>
      {expanded ? (
        <>
          <span>{children}</span>
          <Button size={24} onClick={() => setExpanded(false)} className="float-right cursor-pointer">
            Collapse
          </Button>
        </>
      ) : (
        <TruncateMarkup
          lines={lines}
          ellipsis={
            ellipsis || (
              <span onClick={() => setExpanded(true)} className="cursor-pointer font-sans text-text-secondary-1">
                {" "}
                ... more
              </span>
            )
          }
        >
          <span>{children}</span>
        </TruncateMarkup>
      )}
    </div>
  );
};

export const DescriptionDetailsTruncated = ({
  children,
  lines = 4,
  className,
  ellipsis,
}: React.PropsWithChildren<{ className?: string; lines?: number; ellipsis?: React.ReactNode }>) => {
  return (
    <dd
      className={classNames(
        "max-w-prose rounded-ms p-4 text-13-18 text-oldgray-900",
        className,
        PRIVACY_CLASSNAMES["high"],
      )}
    >
      <TruncatedExpandable lines={lines} className={className} ellipsis={ellipsis}>
        {children}
      </TruncatedExpandable>
    </dd>
  );
};

/** Supports markdown */
export const DescriptionDetailsMarkdownTruncated = ({
  children,
  lines = 4,
  className,
  textClassName,
  ellipsis,
  markdown = false,
}: {
  children: string;
  className?: string;
  textClassName?: string;
  lines?: number;
  ellipsis?: React.ReactNode;
  markdown?: boolean;
}) => {
  const [expanded, setExpanded] = useState(false);
  const [hasBeenTruncated, setHasBeenTruncated] = useState(true);

  // This can be React etc.
  const expandedNode = markdown ? (
    <div className={cn("prose prose-xs -my-6", textClassName, PRIVACY_CLASSNAMES["high"])}>
      <SafeMarkdown>{children}</SafeMarkdown>
    </div>
  ) : (
    <span className={classNames(textClassName, "break-words")}>{children}</span>
  );
  const truncatedElement = <span className={classNames(textClassName, "break-words")}>{children}</span>;

  // This is just a dumb hack to force a re-render after 100ms. There is a hard to fix bug where the truncations
  // were wrong on first render (like it would get the first character and then just show the ellipsis). It calculates
  // it correctly after a re-render so we just do that.
  const [count, setCount] = useState(0);
  useTimeout(() => {
    setCount(count + 1);
  }, 100);

  // children has to be HTML markup (not a React component!) for the truncation to work.
  return (
    <dd
      className={classNames(
        "flex min-w-0 max-w-prose break-words py-2 text-13-20 text-oldgray-900 ",
        className,
        PRIVACY_CLASSNAMES["high"],
      )}
    >
      {expanded || !hasBeenTruncated ? (
        expandedNode
      ) : (
        <TruncateMarkup
          lines={lines}
          ellipsis={
            ellipsis || (
              <span onClick={() => setExpanded(true)} className="cursor-pointer text-text-secondary-1">
                {" "}
                ... more
              </span>
            )
          }
          onTruncate={(isTruncated) => setHasBeenTruncated(isTruncated)}
        >
          {/* Has to be within an element or will break, has to not be a react component or will break */}
          {/* If no truncatation is needed we allow markdown view to work */}
          <div>{truncatedElement}</div>
        </TruncateMarkup>
      )}
    </dd>
  );
};
