import { getShortId } from "@/lib/string";
import { classNames } from "@/lib/utils";
import { Environment } from "@/types/app/environment";
import { FileType } from "@/types/app/file";
import _ from "lodash";
import { forwardRef } from "react";
import { ToastVariant, hlToast } from "../Toast";
import { ConditionalTooltip } from "../Tooltip";
import { EntityIcon, EntityIconSize, IdEntity } from "./EntityIcon";

export type EntityLabelSize = 18 | 20 | 24;

interface BaseEntityProps {
  size: EntityLabelSize;
  /** This will enable actions like click to copy ID and additional tooltips
   *  - should only be set to true if this is used where it is not a trigger for a popover */
  interactive?: boolean;
  hideIcon?: boolean;
  rootClassName?: string;
  styling?: "solid" | "ghost";
  rightElements?: React.ReactNode;
}

type OptionalFileName = {
  // If `fileName` is provided, it will be displayed in the label.
  fileName?: string;
};

type FileEntityProps = {
  entity: FileType;
  versionId: string;
  /** List of environments this is deployed in */
  deployedEnvironments: Environment[];
  committed: boolean;
  hideVersionId?: boolean;
} & OptionalFileName;

type FileEnvironmentEntityProps = {
  entity: FileType;
  environment: string;
} & OptionalFileName;

type IdEntityProps = {
  entity: IdEntity;
  id: string;
  truncate?: boolean;
};

export type FileEntityLabelProps = BaseEntityProps & FileEntityProps;
export type IdEntityLabelProps = BaseEntityProps & IdEntityProps;
export type FileEnvironmentEntityLabelProps = BaseEntityProps & FileEnvironmentEntityProps;

export type EntityLabelProps = FileEntityLabelProps | IdEntityLabelProps | FileEnvironmentEntityLabelProps;

const isIdEntityProps = (props: EntityLabelProps): props is IdEntityLabelProps => {
  return (props as IdEntityProps).id !== undefined;
};

const isFileEnvironmentEntityProps = (props: EntityLabelProps): props is FileEnvironmentEntityLabelProps => {
  return (props as FileEnvironmentEntityProps).environment !== undefined;
};

const isFileEntityProps = (props: EntityLabelProps): props is FileEntityLabelProps => {
  // In the case where enough props are specified to satisfy both FileEntityProps and FileEnvironmentEntityProps,
  // we should default to FileEnvironmentEntityProps.
  if (isFileEnvironmentEntityProps(props)) return false;
  return (props as FileEntityProps).versionId !== undefined;
};

/**
 * Entity label - i.e. the tag which show the file name, type and version.
 *
 * https://www.figma.com/file/Ya7mCOIZJp1UdBCH3VxnEL/Components-v2?type=design&node-id=2093-77&mode=design&t=bMlfNmOwOFNz4j5Y-0
 */
export const EntityLabel = forwardRef<HTMLDivElement, EntityLabelProps>((props: EntityLabelProps, ref) => {
  const { entity, size, interactive, hideIcon, styling = "solid" } = props;
  const isIdEntity = isIdEntityProps(props);
  const isFileEnvironmentEntity = isFileEnvironmentEntityProps(props);
  const isFileEntity = isFileEntityProps(props);
  const truncate = (props as IdEntityProps).truncate ?? true;
  let displayFile: boolean,
    deployedEnvironments: Environment[] | undefined,
    fileName: React.ReactNode,
    committed,
    id: string;
  if (isIdEntity) {
    displayFile = false;
    deployedEnvironments = undefined;
    fileName = null;
    committed = false;
    id = (props as IdEntityProps).id;
  } else if (isFileEnvironmentEntity) {
    displayFile = props.fileName !== null && props.fileName !== undefined;
    deployedEnvironments = undefined;
    fileName = (props.fileName as string | null) || null;
    committed = false;
    id = props.environment;
  } else if (isFileEntity) {
    displayFile = props.fileName !== null && props.fileName !== undefined;
    deployedEnvironments = props.deployedEnvironments;
    fileName = (props.fileName as string | null) || null;
    committed = props.committed;
    id = props.versionId;
  } else {
    console.log("Invalid EntityLabelProps", props);
    throw new Error("Invalid EntityLabelProps");
  }

  const rootSizeStyles = ENTITY_LABEL_ROOT_SIZE_STYLES[size];
  const rootTypeStyles =
    styling === "solid" ? SOLID_ENTITY_LABEL_ROOT_TYPE_STYLES[entity] : GHOST_ENTITY_LABEL_ROOT_TYPE_STYLES[entity];
  const rootSizeFileStyles = ENTITY_LABEL_ROOT_SIZE_FILE_STYLES[size][displayFile ? "true" : "false"];
  const entityIconSize = ENTITY_ICON_SIZE[size];
  const deployed = deployedEnvironments && deployedEnvironments.length > 0;

  return (
    // Note that Radix recommends using a focusable component as the root element
    // for accessibility (and not a div).
    // See: https://www.radix-ui.com/primitives/docs/guides/composition#changing-the-element-type
    //
    // Also, we spread props here for so that this can be used as a Radix's Tooltip.Trigger.
    // We can consider making the types more accurate (e.g. EntityLabelProps & HTMLDivElementProps)
    // but I think that's at the expense of developer experience.
    <div
      className={classNames(
        "inline-flex select-none items-center truncate font-normal",
        rootSizeStyles,
        rootSizeFileStyles,
        rootTypeStyles,
        props.rootClassName,
      )}
      ref={ref}
    >
      {/* File type icon */}
      {displayFile && <EntityIcon type={entity} size={entityIconSize} />}
      {/* File */}
      {displayFile && <span className="text-text-base-1">{fileName}</span>}
      {/* Version hash / ID */}
      {!("hideVersionId" in props && props.hideVersionId) && (
        <ConditionalTooltip
          condition={!!interactive}
          side="right"
          delayDuration={100}
          content={
            <span className="font-mono text-11-12">
              <span className="flex w-full justify-between">
                <span>
                  {isIdEntity
                    ? _.startCase(entity)
                    : isFileEntity
                      ? "Version"
                      : isFileEnvironmentEntity
                        ? "Environment"
                        : null}{" "}
                  {id}
                </span>
                <span className="whitespace-nowrap ">&nbsp;- click to copy</span>
              </span>
            </span>
          }
        >
          <span
            className={classNames(
              "inline-flex min-w-0 shrink-0 items-center font-mono text-text-base-1",
              displayFile ? "opacity-60" : "",
              isFileEnvironmentEntity ? "gap-6" : "gap-2",
            )}
            onClick={() => {
              if (!interactive) {
                return;
              }
              navigator.clipboard.writeText(id).then(() => {
                hlToast({ variant: ToastVariant.Success, title: `ID copied to clipboard` });
              });
            }}
          >
            {hideIcon ? null : (
              <>
                {isIdEntity && <EntityIcon type={entity} size={12} />}
                {isFileEntity && <EntityIcon type={"hash"} size={12} />}
              </>
            )}
            {(isIdEntity || isFileEntity) && (truncate ? getShortId(id) : id)}
            {isFileEnvironmentEntity && <DotSeparator />}
            {isFileEnvironmentEntity && id}
          </span>
        </ConditionalTooltip>
      )}
      {/* Committed/Deployed dot */}
      {committed && (
        <ConditionalTooltip
          condition={!!interactive}
          content={
            deployedEnvironments && deployedEnvironments.length > 0
              ? `Deployed to ${deployedEnvironments.map((env) => env.name).join(", ")}`
              : "Not deployed"
          }
        >
          <span>
            <EntityIcon type={deployed ? "committed-deployed" : "committed-not-deployed"} size={entityIconSize} />
          </span>
        </ConditionalTooltip>
      )}
      {props.rightElements}
    </div>
  );
});
EntityLabel.displayName = "EntityLabel";

const ENTITY_LABEL_ROOT_SIZE_STYLES: Record<EntityLabelSize, string> = {
  18: "text-10-10 h-[18px] px-4 py-2 rounded-ms",
  20: "text-12-14 h-20 px-6 py-5 rounded-ms",
  24: "text-12-14 h-24 px-6 py-5 rounded-ms",
};

// Styles that depend on both size and displayFile
const ENTITY_LABEL_ROOT_SIZE_FILE_STYLES: Record<EntityLabelSize, { [key in "true" | "false"]: string }> = {
  18: {
    true: "gap-4",
    false: "gap-4",
  },
  20: {
    true: "gap-6",
    false: "gap-4",
  },
  24: {
    true: "gap-6",
    false: "gap-4",
  },
};

export const SOLID_ENTITY_LABEL_ROOT_TYPE_STYLES: Record<FileType | IdEntity, string> = {
  agent: "bg-background-base-3",
  prompt: "bg-background-base-3",
  tool: "bg-violet-100",
  dataset: "bg-blue-100",
  evaluator: "bg-orange-50",
  flow: "bg-cyan-50",
  datapoint: "bg-background-base-3",
  log: "bg-background-base-3",
  batch: "bg-background-base-3",
};

export const GHOST_ENTITY_LABEL_ROOT_TYPE_STYLES: Record<FileType | IdEntity, string> = {
  prompt: "",
  tool: "",
  dataset: "",
  evaluator: "",
  flow: "",
  datapoint: "",
  log: "",
  batch: "",
  agent: "",
};

const ENTITY_ICON_SIZE: Record<EntityLabelSize, EntityIconSize> = {
  18: 12,
  20: 12,
  24: 14,
};

const DotSeparator = () => <div className="h-[3px] w-[3px] shrink-0 rounded-full bg-icon-base-1 opacity-25" />;
