import * as ScrollArea from "@radix-ui/react-scroll-area";
import React from "react";
import { classNames } from "./utils/classNames";

interface Props {
  children: React.ReactNode;
  orientation?: "vertical" | "horizontal" | "both";
  size?: 7 | 11;
}

export const Scrollable = ({ children, orientation = "vertical", size = 7 }: Props) => {
  return (
    <ScrollArea.Root className="relative h-full w-full rounded-[inherit]">
      {/* Viewport renders a child wrapping div (data-radix-scroll-area-content). 
          `*:flex` and `*:flex-col` affect this div, ensuring the wrapper arranges its children properly */}
      <ScrollArea.Viewport className="h-full w-full rounded-[inherit] *:flex *:flex-col">
        {children}
      </ScrollArea.Viewport>

      {(orientation === "vertical" || orientation === "both") && <Scrollbar orientation="vertical" size={size} />}
      {(orientation === "horizontal" || orientation === "both") && <Scrollbar orientation="horizontal" size={size} />}
      <ScrollArea.Corner className="bg-gray-100" />
    </ScrollArea.Root>
  );
};

const Scrollbar = React.forwardRef<
  React.ElementRef<typeof ScrollArea.ScrollAreaScrollbar>,
  React.ComponentPropsWithoutRef<typeof ScrollArea.ScrollAreaScrollbar> & Pick<Props, "size">
>(({ className, orientation = "vertical", size, ...props }, ref) => {
  return (
    <ScrollArea.Scrollbar
      ref={ref}
      orientation={orientation}
      className={classNames(
        "z-40 flex overflow-hidden rounded bg-gray-100",
        size === 7 && "p-2",
        size === 11 && "p-[3px]",
        orientation === "vertical" && size === 7 && "w-[7px]",
        orientation === "vertical" && size === 11 && "w-[11px]",
        orientation === "horizontal" && size === 7 && "h-[7px]",
        orientation === "horizontal" && size === 11 && "h-[11px]",
      )}
    >
      <ScrollArea.Thumb className="flex-auto rounded bg-gray-200" />
    </ScrollArea.Scrollbar>
  );
});

Scrollbar.displayName = "Scrollbar";
